<script setup>
import { computed } from 'vue'
import {relativeUrl, formatPrice} from '@helpers/utils'
import Image from '@components/Image.vue'
import Icon from '@components/Icon.vue'
import ListingRating from '@components/ListingRating.vue'
import ListingTags from '@components/ListingTags.vue'
import AddToCartButton from '@components/AddToCartButton.vue'

const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  showTag: {
    type: Boolean,
    default: true,
  },
})

const url = computed(() => {
  return relativeUrl(props.listing.url)
})
const mainImage = computed(() => {
  return props.listing?.images?.[0]
})
const price = computed(() => {
  return props.listing?.products?.[0]?.price
})
const compareAtPrice = computed(() => {
  return props.listing?.products?.[0]?.compare_at_price
})
const hasDiscount = computed(() => {
  return Number(compareAtPrice.value) > Number(price.value)
})
const isBundle = computed(() => {
  return props.listing?.products?.[0]?.quantity > 1
})
const savings = computed(() => {
  return Math.round(((compareAtPrice.value - price.value) / compareAtPrice.value) * 100)
})
const pieces = computed(() => {
  return props.listing?.products?.[0]?.stocks?.length === 1
      ? (props.listing?.products?.[0]?.stocks?.[0]?.pieces * props.listing?.products?.[0]?.stocks?.[0]?.pivot?.quantity)
      : 0
})
const addToCartPayload = computed(() => {
  return {
    product_id: props.listing?.products?.[0]?.id,
    listing_plan_id: null,
    quantity: 1,
  }
})

function navigate(e) {
  if (e.target.classList.contains('rating__text--total')) {
    e.preventDefault()
    window.location.href = url.value + '#reviews'
  }
}
</script>

<template>
  <div class="listing">
    <template v-if="showTag">
      <div v-if="listing?.tags?.includes('New Look')" class="listing__tag">
        <div>NEW <strong>LOOK</strong></div>
      </div>
      <div v-else-if="listing?.tags?.includes('Low Stock')" class="listing__tag listing__tag--low-stock">
        <div>LOW <strong>STOCK</strong></div>
      </div>
      <div v-else-if="listing?.tags?.includes('Flash Sale')" class="listing__tag listing__tag--flash-sale">
        <img src="/images/flash-sale.svg" alt="Flash sale" loading="lazy" />
      </div>
      <div v-else-if="listing?.tags?.includes('Limited Release')" class="listing__tag listing__tag--limited-release">
        <img src="/images/limited-release.png" alt="Limited Release" loading="lazy" />
        <div>LIMITED <strong>RELEASE</strong></div>
      </div>
    </template>

    <div v-if="savings > 0" class="listing__savings">
      <Icon v-if="isBundle" type="bundle" />
      <span>{{ savings }}% Off</span>
    </div>

    <a :href="url" class="listing__link" @click="navigate">
      <div class="listing__image" v-if="mainImage">
        <Image
            :src="mainImage"
            :alt="mainImage.alt || listing.title"
            sizes="(max-width: 768px) calc(50vw - 56px), 100vw"
            loading="lazy"
        />
      </div>
      <div class="listing__info">
        <div class="listing__stats">
          <div>
            <ListingRating
                class="listing__rating"
                :total="listing.total_reviews"
                :rating="listing.rating"
            />
          </div>

          <div class="listing__price">
            <span v-if="hasDiscount" class="listing__price--compare">${{ formatPrice(compareAtPrice) }}</span>
            <span>${{ formatPrice(price) }}</span>
            <small v-if="pieces > 1">/{{ pieces }}ct</small>
          </div>
        </div>
        <h5 class="listing__title">{{ listing.title }}</h5>
        <ListingTags :listing="listing" />
      </div>
    </a>
    <AddToCartButton class="listing__button" :listing="listing" :payload="addToCartPayload" :disabled="!!listing?.out_of_stock">Add To Cart</AddToCartButton>
  </div>
</template>

<style lang="scss" scoped>
.listing {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: var(--shades-white, #FFF);
  box-shadow: 0 4px 6px rgba(16, 24, 40, 0.1), 0 10px 15px rgba(16, 24, 40, 0.1);
  height: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    border-radius: 16px;
  }

  &:hover {
    .listing__title {
      text-decoration: underline;
    }
  }

  .listing__savings {
    position: absolute;
    top: 14px;
    right: 16px;
    display: flex;
    padding: 0 2px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    background: var(--Watermelon, #D75858);
    color: var(--Neutral-50, #F7F6F2);
    font-family: Outfit, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    z-index: 1;
  }

  .listing__tag {
    position: absolute;
    background-color: #3B717B;
    color: #F5F5F5;
    text-align: center;
    font-size: 13.333px;
    font-weight: 400;
    line-height: 14.667px;
    height: 52px;
    width: 52px;
    top: -4px;
    left: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 2px 6px 2px;
    z-index: 1;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 21.333px;
      height: 75px;
      width: 75px;
      border-radius: 12px 2px 12px 2px;
    }

    &.listing__tag--flash-sale {
      background-color: transparent;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.listing__tag--low-stock {
      background: var(--Watermelon, #D75858);

      strong {
        display: block;
      }
    }

    &.listing__tag--limited-release {
      flex-direction: column;
      background: var(--Light-navy, #183D4F);
      gap: 5px;

      img {
        display: block;
        width: 16px;
        height: 16px;

        @media screen and (min-width: 768px) {
          width: 27px;
          height: 25.364px;
        }
      }

      div {
        font-size: 10px;
        line-height: 10.642px;
        font-weight: 700;

        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 15.349px;
        }
      }

      strong {
        display: block;
        font-weight: 900;
      }
    }
  }

  .listing__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    flex-grow: 1;
  }

  .listing__image {
    position: relative;

    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
      height: auto;
      text-align: center;
    }
  }

  .listing__info {
    padding: 8px;
    overflow: hidden;

    @media screen and (min-width: 768px) {
      padding: 0 16px 16px 16px;
    }

    .listing__title {
      color: var(--neutral-1000, #3C3833);
      font-family: Outfit, serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      margin: 4px 0 8px 0;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        margin: 8px 0;
      }
    }

    .listing__stats {
      display: flex;
      justify-content: space-between;
      align-items: center;

      :deep(.rating) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;

        .rating__text {
          font-size: 12px;
          padding-left: 0;
          font-weight: 500;

          @media screen and (min-width: 768px) {
            padding-top: 0;
            font-size: 14px;
          }

          &.rating__text--total {
            color: #3B717B;
            text-decoration-line: underline;
          }
        }

        img {
          height: 12px;

          @media screen and (min-width: 768px) {
            height: 16px;
          }
        }
      }

      .listing__price {
        color: var(--neutral-1000, #3C3833);
        font-family: Outfit, serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
        }

        .listing__price--compare {
          text-decoration: line-through;
          color: var(--Neutral-400, #B1AFAD);
          font-weight: 300;
          padding-right: 2px;
        }

        small {
          color: var(--Neutral-1000, #3C3833);
          font-size: 10px;
          font-weight: 300;

          @media screen and (min-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  :deep(.listing__button) {
    cursor: pointer;
    border-radius: 0 0 8px 8px;
    background: #DB7E5A;
    box-shadow: 0 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);

    color: var(--shades-white, #FFF);
    text-align: center;
    font-family: Outfit, serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    padding: 6px;

    width: 100%;
    outline: none;
    border: 0;

    @media screen and (min-width: 768px) {
      padding: 12px 10px;
      font-size: 16px;
    }

    &:hover {
      background: var(--Light-Orange, #EFA670);
    }
  }
}
</style>
